<template>
  <v-container>
    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="auto"
      >
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Pozzyvibes Current Events
          </h1>
        </v-col>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
            <v-col
                v-for="event in events"
                :key="event.title"
                :cols="auto"
            >
              <v-card
                  :loading="loading"
                  class="mx-auto my-12"
                  
              >
                <v-img
                    :src="event.src"
                    class="white--text align-end"
                >
                </v-img>

                <v-card-text>
                  <div v-html="event.title"> </div>
                  <div v-html="event.description"> </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-card
                  class="mx-auto my-12"
                  height="800px"
              >
                <v-card-text>
                  <embed
                      src="https://calendar.google.com/calendar/embed?src=7kab5hnaeigvdp10ct4056tudk%40group.calendar.google.com&ctz=America%2FNew_York"
                      width="100%"
                      height="740px"
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyEvents',
  data: () => ({
    events: [
     {
        title: '<p><h3>Sugar Mill Elementary School PTA - Parents Night Out!</p>',
        description: '<p>Friday, February 21, 2025<br>Port Orange, FL <a href="https://www.facebook.com/smepantherpta" target="_blank">facebook.com</a></p>. For SME Students Only!</a></p>',
        src: require("../assets/events/parents-night-out-pozzyvibes-photo-booth-2025.png")
      },
      {
        title: '<p><h3>More to Come in 2025</p>',
        description: '<p>Check back soon soon!<br>2025 events will be rolling out soon!<br>Book us for your event at info@pozzyvibes.com & check us out on <a href="https://www.facebook.com/Pozzyvibes" target="_blank">Facebook</a></p>',
        src: require("../assets/events/2025-Events-Coming-Soon-Pozzyvibes.png")
      }],

  }),
}
</script>

<style scoped>
eb1 {
  text-align: center;
}
</style>